<template lang="pug">
om-modal(:name="name" width="550" @beforeOpen="beforeOpen")
  template(#modal-header)
    .d-flex
      .font-weight-bold.align-self-center SP import/export ({{ domainData.domain }})
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide(name)" title="close")
        close-icon(:width="14" :height="14" :color="'#C2C2C2'")
  template(#modal-body)
    om-pills.mt-5(:pills="pills" :selected="tab" @select="tab = $event")
    .mt-2
      template(v-if="tab === 'import'")
        loading-logo(v-if="uploadLoading")
        upload-drop-zone(v-else :key="counter" @change="upload($event)")
          template(#btn-text) {{ $t('upload') }}
      template(v-else)
        om-button(primary small @click="exportCSV" :loading="exportLoading") {{ $t('export') }}
</template>

<script>
  import UploadDropZone from '@/components/UploadDropZone';
  import { saveAs } from 'file-saver/FileSaver';

  export default {
    components: {
      UploadDropZone,
    },
    data() {
      return {
        domainData: {
          domain: '',
          _id: '',
        },
        counter: 0,
        tabs: ['import', 'export'],
        tab: 'import',
        name: 'sp-import-export',
        uploadLoading: false,
        exportLoading: false,
        campaignId: this.$route.params.id,
      };
    },
    computed: {
      pills() {
        return this.tabs.map((key) => ({
          key,
          text: this.$t(key),
        }));
      },
    },
    methods: {
      beforeOpen({ params: { domain } }) {
        this.domainData = domain;
      },
      async upload(file) {
        this.uploadLoading = true;
        const formData = new FormData();
        formData.append('sp-file', file);
        try {
          await this.$axios.post(`smart-personalizer/import/${this.domainData._id}`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          });

          this.counter++; // allow multiple upload this remount upload-drop-zone component
          this.$notify({ type: 'success', title: 'Successfully uploaded' });
        } catch (e) {
          this.$notify({ type: 'error', title: e.message });
        }
        this.uploadLoading = false;
      },
      async exportCSV() {
        this.exportLoading = true;
        try {
          const response = await this.$axios.get(
            `smart-personalizer/export/${this.domainData._id}`,
            {
              responseType: 'blob',
            },
          );
          saveAs(response.data, `export-sp-${this.domainData.domain}-${new Date().getTime()}.csv`);
        } catch (e) {
          this.$notify({ type: 'error', title: `Something went wrong<br>(${e.message})` });
        }
        this.exportLoading = false;
      },
    },
  };
</script>
