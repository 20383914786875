<template lang="pug">
div
  input(
    ref="fileInput"
    type="file"
    style="display: none"
    :accept="accept"
    @change="handleFileSelect"
  )
  .om-upload-drop-zone(
    @drop.prevent="handleFileDrop"
    @dragover.prevent="stateClass = 'state-dragover'"
    @dragenter.prevent="stateClass = 'state-dragover'"
    @dragleave.prevent="stateClass = ''"
    @dragend.prevent="stateClass = ''"
    :class="stateClass"
  )
    .button-container
      om-button.mr-2(primary small icon="upload" @click.prevent="$refs.fileInput.click()")
        slot(name="btn-text") {{ $t('uploadFont') }}
      span.drop-file-here {{ $t('dropFileHere') }}
</template>
<script>
  export default {
    props: {
      accept: {
        type: String,
      },
    },

    data() {
      return {
        stateClass: '',
      };
    },

    methods: {
      handleFileDrop(e) {
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
          this.$emit('change', files[0]);
        }
      },

      handleFileSelect() {
        const input = this.$refs.fileInput;
        if (input.files.length > 0) {
          this.$emit('change', input.files[0]);
        }
      },
    },
  };
</script>

<style lang="sass">
  .om-upload-drop-zone
    text-align: center
    background-color: #E7E7E7
    padding: 1.375rem 0
    width: 100%
    transition: .4s
    align-items: center
    height: 120px
    display: flex
    justify-content: center
    border-radius: 4px
    &.state-dragover
      opacity: 0.6
    .button-container
      display: flex
      max-height: 40px
      .drop-file-here
        display: flex
        align-items: center
        font-size: 14px
        color: #505763
</style>
